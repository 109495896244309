<template>
<span>
    <v-layout mb-4>
        <v-flex xs10>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Fretes - Edição</h2>
        </v-flex>
        <v-flex xs2 text-xs-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'store-shippings'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-store-shipping :storeshipping="storeshipping" :update="true"></form-store-shipping>
</span>
</template>

<script>
import FormStoreShippingComponent from "./partial/FormStoreShippingComponent";

export default {
    name: "EditStoreShippingComponent",
    created() {
        this.loadStoreShipping()
    },
    props: {
        stor_sh_id: {
            require: true
        }
    },
    data() {
        return {
            storeshipping: {
                stor_sh_id: '',
                stor_sh_nome: '',
                stor_sh_nick: '',
                stor_sh_tipo: '',
                stor_sh_mensagem: '',
                stor_sh_valor: '',
                stor_sh_is_correios: false,
                stor_sh_is_gratis: false,
                stor_sh_gratis_maior_q: '',
                stor_sh_is_lista_presente: false,
                stor_sh_cep_origem: '',
                stor_sh_status: false,
            },
        }
    },
    methods: {
        loadStoreShipping() {
            this.$store.dispatch('loadStoreShipping', this.stor_sh_id)
                .then(response => {
                    this.storeshipping = response
                    })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formStoreShipping: FormStoreShippingComponent
    }
};
</script>

<style scoped>

</style>
